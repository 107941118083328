"use client";
import { Toaster } from "sonner";

import { createPolyfill } from "@/lib/polyfill";
import { runtimeEnv } from "@/lib/runtime";
import * as Sentry from "@sentry/nextjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode, use, useEffect, useState } from "react";
import { TooltipProvider } from "../ui/tooltip";
const polyfillPromise = createPolyfill();
async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("@/mocks/browser");

  return worker.start();
}

export function RootLayout({ children }: { children: ReactNode }) {
  use(polyfillPromise);
  useEffect(() => {
    // @ts-expect-error
    if (globalThis.INIT_MSW) {
      return;
    }

    // @ts-expect-error
    globalThis.INIT_MSW = true;
    if (runtimeEnv.NEXT_PUBLIC_ENABLE_MOCKS) {
      enableMocking();
    }
  }, []);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: (error, variables, context) => {
              console.error("Mutation error", error, variables, context);
              Sentry.captureException(error, {
                contexts: {
                  mutation: {
                    variables,
                    context,
                  },
                },
              });
            },
          },
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            // staleTime: 60 * 1000,
          },
        },
      }),
  );

  return (
    <TooltipProvider>
      <QueryClientProvider client={queryClient}>
        {children}
        <Toaster position="bottom-right" closeButton />
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      </QueryClientProvider>
    </TooltipProvider>
  );
}
